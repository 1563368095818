import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Alert from "@mui/material/Alert";
import { Circles } from "react-loading-icons";
import Stack from "@mui/material/Stack";
export const Time = () => {
  // const [isLogin, setIsLogin] = useState(false);
  const [punchint, setPunchInt] = useState();
  const [punchinRemark, setPunchInRemark] = useState();
  const [punchoutRemark, setPunchOutRemark] = useState();
  const [punchinstatus, setPunchInStatus] = useState(true);
  const [punchinstatus1, setPunchInStatus1] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const defaultDate = getCurrentDate();

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const defaultTime = getCurrentTime();

  // const handlePunchButtonClick = () => {
  //   setIsLogin(!isLogin);
  // };

  const Punchin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let userInfo = JSON.parse(localStorage.getItem("token"));
    const token = userInfo.token;

    console.log(token, "username");
    let result = await fetch(
      "https://humhai.in/hrms/punchin_punchout/",
      {
        method: "POST",
        body: JSON.stringify({ token: token, punchin_note: punchinRemark }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
        },
      }
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });

    console.log("after response");
    result = await result.json();
    console.log(result, "result");
    console.log(result.opt, "true");
    setPunchInStatus(false);
    if (result.opt === 2) {
      setPunchInStatus(false);
      setPunchInStatus1(true);
      setIsLoading(false);
      setSuccess(true);
      Punchintime();
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
      // setPunchInStatus(2);
    }
  };
  const Punchout = async (e) => {
    e.preventDefault();
    var retVal = window.confirm("Are you sure punch out ?");
    if (retVal === true) {
      setIsLoading(false);
      let userInfo = JSON.parse(localStorage.getItem("token"));
      const token = userInfo.token;

      console.log(token, "username");
      let result = await fetch(
        "https://humhai.in/hrms/punchin_punchout/",
        {
          method: "POST",
          body: JSON.stringify({ token: token, punchout_note: punchoutRemark }),
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
          },
        }
      )
        .then((res) => {
          console.log(res.token, "response");
          return res;
        })
        .catch((error) => {
          console.log(error, "error");
        });

      console.log("after response");
      result = await result.json();
      console.log(result, "result");
      console.log(result.opt, "false");
      // setPunchInStatus(result.opt);
      if (result.opt === 3) {
        Punchintime();
        setPunchInStatus1(false);
        setSuccess(true);
        setIsLoading(false);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);

        console.log(punchinstatus);
      }
      return true;
    } else {
      return false;
    }
  };

  const Punchintime = () => {
    let userInfo = JSON.parse(localStorage.getItem("user-info"));

    let id = userInfo.id;
   
    fetch(`https://humhai.in/hrms/punchin_punchout/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setPunchInt(data.data);
        
        if (data.opt === 3) {
          setPunchInStatus(false);
        }
        if (data.opt === 2) {
          setPunchInStatus(false);
          setPunchInStatus1(true);
        }
      });
  };

  // console.log(punchint, "punchint");

  useEffect(() => {
    Punchintime();
  }, []);

  return (
    <>
      {success ? (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="success">
            This is a success alert — check it out!
          </Alert>
        </Stack>
      ) : (
        ""
      )}

      {isLoading ? (
        <div
          md={6}
          style={{
            position: "absolute",
            top: "30%",
            right: "30%",
            zIndex: 1,
          }}
        >
          {" "}
          <Circles stroke="#0000FF" />
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 20,
            margin: 10,
            background: "#FFF",
            width: "87%",
            borderRadius: 20,
          }}
        >
          {punchinstatus ? (
            <form onSubmit={Punchin}>
              <div>
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Punch In
                </div>
                <hr />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div style={{ fontSize: "14px" }}>Date*</div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        size="small"
                        type="date"
                        fullWidth
                        defaultValue={defaultDate}
                        disabled
                        style={{ backgroundColor: "#F2F2F2" }}
                        InputProps={{
                          endAdornment: (
                            <CalendarTodayIcon style={{ marginLeft: "5px" }} />
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ fontSize: "14px" }}>Time*</div>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <TextField
                        type="time"
                        size="small"
                        fullWidth
                        defaultValue={defaultTime}
                        disabled
                        style={{ backgroundColor: "#F2F2F2" }}
                        InputProps={{
                          endAdornment: (
                            <AccessTimeIcon style={{ marginLeft: "5px" }} />
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ fontSize: "14px" }}>Note*</div>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      fullWidth
                      rows={4}
                      value={punchinRemark}
                      onChange={(e) => {
                        setPunchInRemark(e.target.value);
                      }}
                      placeholder="Type Here"
                      style={{
                        backgroundColor: "#F2F2F2",
                      }}
                    />
                  </Grid>
                </Grid>
                <hr />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div style={{ fontSize: "14px" }}>*Required</div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-success"
                      // onClick={handlePunchButtonClick}
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#0A2558",
                      }}
                    >
                      {/* {isLogin ? "Out" : "In"} */}
                      In
                    </button>
                  </div>
                </div>
              </div>
            </form>
          ) : punchinstatus1 ? (
            <div>
              <div>
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Punch Out
                </div>
                <hr />
                <div>
                  {/* <div style={{ fontSize: "14px" }}>Punched in time</div> */}
                  {/* <input defaultValue={punchint?.punchin} ${defaultTime}`} disabled /> */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div style={{ fontWeight: "bold" }}>Punch In Time</div>
                      <div>
                        {/* {punchint?.punchin} */}
                        {punchint?.punchin?.split("T")[0]}&nbsp;&nbsp;
                        {punchint?.punchin?.split("T")[1].split(".")[0]}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div style={{ fontWeight: "bold" }}>Punch Out Time</div>
                      <div>{punchint?.punchout}</div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div style={{ fontWeight: "bold" }}>Punch In Note</div>
                      <div>{punchint?.punchin_note}</div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div style={{ fontWeight: "bold" }}>Punch Out Note</div>
                      <div>{punchint?.punchout_note}</div>
                    </Grid>
                  </Grid>

                  {/* <div>
                  <table>
                    <tr>
                      <td>punch in time</td>
                      <td>{punchint?.punchin}</td>
                    </tr>
                    <tr>
                      <td>punch out time</td>
                      <td>{punchint?.punchout}</td>
                    </tr>
                    <tr>
                      <td>punch in note</td>
                      <td>{punchint?.punchin_note}</td>
                    </tr>
                    <tr>
                      <td>punch out note</td>
                      <td>{punchint?.punchout_note}</td>
                    </tr>
                  </table>
                </div> */}

                  <form onSubmit={Punchout}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <div style={{ fontSize: "14px" }}>Date*</div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            size="small"
                            type="date"
                            fullWidth
                            defaultValue={defaultDate}
                            style={{
                              backgroundColor: "#F2F2F2",
                            }}
                            disabled
                            InputProps={{
                              endAdornment: (
                                <CalendarTodayIcon
                                  style={{ marginLeft: "5px" }}
                                />
                              ),
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div style={{ fontSize: "14px" }}>Time*</div>
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <TextField
                            size="small"
                            type="time"
                            fullWidth
                            defaultValue={defaultTime}
                            disabled
                            style={{
                              backgroundColor: "#F2F2F2",
                            }}
                            InputProps={{
                              endAdornment: (
                                <AccessTimeIcon style={{ marginLeft: "5px" }} />
                              ),
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div style={{ fontSize: "14px" }}>Note*</div>
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          fullWidth
                          style={{
                            backgroundColor: "#F2F2F2",
                          }}
                          value={punchoutRemark}
                          onChange={(e) => {
                            setPunchOutRemark(e.target.value);
                          }}
                          rows={4}
                          placeholder="Type Here"
                        />
                      </Grid>
                    </Grid>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div style={{ fontSize: "14px" }}>*Required</div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          class="btn btn-success"
                          style={{
                            borderRadius: "20px",
                            backgroundColor: "#0A2558",
                          }}
                        >
                          Out
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div style={{ fontWeight: "bold" }}>Punch In Time</div>
                <div>{punchint?.punchin}</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ fontWeight: "bold" }}>Punch Out Time</div>
                <div>{punchint?.punchout}</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ fontWeight: "bold" }}>Punch In Note</div>
                <div>{punchint?.punchin_note}</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ fontWeight: "bold" }}>Punch Out Note</div>
                <div>{punchint?.punchout_note}</div>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </>
  );
};
