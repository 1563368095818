import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@mui/material";
import "../attendance/attendence.css";

export const Attendance = () => {
  const [punchint, setPunchInt] = useState([]);

  const Punchintimeget = async () => {
    try {
      let userInfo = JSON.parse(localStorage.getItem("user-info"));
      if (!userInfo) return;

      let response = await fetch(`https://humhai.in/hrms/punchin_punchout_admin/`, {
        headers: {
          "Authorization": `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      });

      let data = await response.json();

      if (!data || !data.data || !Array.isArray(data.data)) {
        setPunchInt([]);  // Prevent issues if the response is not as expected
      } else {
        setPunchInt(data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setPunchInt([]);  // Set an empty array to prevent errors in rendering
    }
  };
  useEffect(() => {
    Punchintimeget();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          backgroundColor: "#0A2558",
        }}
      >
        <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} md={4}>
            <div style={{ fontWeight: "bold", marginTop: "4%", color: "white" }}>
              Monthly Attendance Report
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
              <div style={{ marginTop: "4%", color: "white" }}>Choose Months</div>
              <div>
                <TextField
                  type="month"
                  InputLabelProps={{ shrink: true }}
                  sx={{ m: 1, minWidth: 180 }}
                  size="small"
                  style={{ backgroundColor: "white", borderRadius: "5px" }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
              <Button variant="contained" sx={{ m: 1, minWidth: 180 }}>
                SUBMIT
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* Attendance Table */}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="container table-responsive py-5">
          <table className="table table-bordered" style={{ textAlign: "center" }}>
            <thead className="thead-dark">
              <tr>
                <th colSpan="3">Date</th>
              </tr>
              <tr>
                <th scope="col">S.no.</th>
                <th scope="col">Employee Name</th>
                <th scope="col">Employee Id</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {Array.isArray(punchint) && punchint.length > 0 ? (
                punchint.map((record, index) =>
                  record ? (  // Check if record is defined
                    <tr key={index}>
                      <td>{record?.punchin || "--:--"}</td>
                      <td>{record?.punchout || "--:--"}</td>
                      <td>{record?.duration || "--:--"}</td>
                    </tr>
                  ) : null
                )
              ) : (
                <tr>
                  <td colSpan="3">No Punch Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Punch In/Out Table */}
        <div className="container table-responsive py-5">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Punch In</th>
                <th scope="col">Punch Out</th>
                <th scope="col">Duration</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {punchint.length > 0 ? (
                punchint.map((record, index) => (
                  <tr key={index}>
                    <td>{record.punchin || "--:--"}</td>
                    <td>{record.punchout || "--:--"}</td>
                    <td>{record.duration || "--:--"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No Punch Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
